<template>
  <div class="regular-content content flex-1">
    <div v-if="$store.state.user.loggedIn === false" class="static-content-nav">
      <SiteLogo @click="goHome"/>
    </div>
    <div v-if="loading" class="content-loader">
      <div class="lds-dual-ring"></div>
    </div>
    <div v-html="content.content" class="the-actual-content"></div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SiteLogo from '@/assets/images/p4u-logo.svg';

export default {
  name: 'ContentPages',
  components: {
    SiteLogo,
  },
  data() {
    return {
      content: {
        id: null,
        content: '',
      },
      loading: true,
    };
  },
  methods: {
    ...mapActions([
      'setActiveTournament',
    ]),
    async getPageContent() {
      try {
        const { data } = await this.axios.get(`${process.env.VUE_APP_API}/content/v1/${this.$route.params.slug}/${this.$route.params.geo}`);
        this.content = data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    goHome() {
      this.$router.push({
        name: 'Login',
        params: {
          geo: this.$route.params.geo,
        },
      });
    },
  },
  created() {
    this.getPageContent();
    if (this.$store.state.activeTournament === null) {
      this.setActiveTournament('firstAvailable');
    }
  },
};
</script>

<style lang="scss">
  .regular-content.content {
    background: $secondary;
    padding: 1rem;
    font-size: 0.85em;
    z-index: 1;
  }
  .content-loader {
    text-align: center;
  }
  .static-content-nav {
    padding-bottom: 1rem;
    svg {
      display: block;
      width: 200px;
      fill: $font;
      cursor: pointer;
    }
  }
</style>
